<template>
<div
  class="d-flex align-center flex-row"
>
  <div>
  </div>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    :nudge-width="200"
    :max-width="$vuetify.breakpoint.xsOnly ? '350px' :'400px'"
    :nudge-bottom="5"
    offset-y
    z-index="100"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        color="white"
        icon
        v-on="on"
        v-bind="attrs"
      >
        <v-icon>mdi-tune</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title class="px-4 primary--text display-1 grey lighten-5">
        <v-icon left>mdi-calendar-month</v-icon>
        Seleccione Fecha a consultar
      </v-card-title>
      <v-card-text class="px-4 pt-1 mb-3">
        <v-row>
          <v-col cols="12">
            <v-radio-group v-model="selected_date" class="pt-0" hide-details>
              <v-row>
                <v-col cols="12" class="pt-0">
                  <v-radio
                    label="Semanal"
                    value="semana"
                    color="icono"
                  >
                    <template #label>
                      <span class="blue-grey--text font-weight-medium">Semana Actual</span>
                    </template>
                  </v-radio>
                </v-col>
                <v-col cols="12" class="pt-0">
                  <v-radio
                    label="Semana Personalizada"
                    value="personalizado"
                    color="icono"
                  >
                    <template #label>
                      <div class="d-flex flex-column justify-start">
                        <span class="blue-grey--text font-weight-medium">Semana Personalizada</span>
                        <small>Seleccionar un rango de fechas máximo 5 días</small>
                      </div>
                    </template>
                  </v-radio>
                </v-col>
              </v-row>
            </v-radio-group>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12"
            class="d-flex flex-row"
            :class="{'flex-row align-center': $vuetify.breakpoint.smAndUp, 'flex-column ': $vuetify.breakpoint.xsOnly}"
            >
            <div>
              <label-form text="Inicio" required/>
              <fecha-input
                v-model="range_inicio"
                color="blue-grey lighten-5"
                hiddenMsg
                :disabled="selected_date != 'personalizado'"/>
            </div>
            <span class="blue-grey--text font-weight-medium mx-4 mt-3 caption">-</span>
            <div>
              <label-form text="Final" required/>
              <fecha-input
                v-model="range_fin"
                hidden-msg
                color="deep-orange lighten-5"
                :disabled="selected_date != 'personalizado'"
              />
            </div>
          </v-col>
        </v-row>
        <v-row>
          <v-col cols="12" class="py-0">
            <span v-if="error.active" style="width:200px" class="error--text caption font-italic" v-text="error.msg"></span>
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions class="py-3 d-flex justify-center">
        <v-row>
          <v-col cols="12" sm="6">
            <v-btn
              outlined
              block
              color="blue-grey"
              small
              @click="menu = false"
            >
              Cancelar
            </v-btn>
          </v-col>
          <v-col cols="12" sm="6">
            <v-btn
              color="primary"
              block
              small
              @click="consultarDatos"
            >
              <v-icon left size="22">mdi-magnify</v-icon>
              Consultar
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-menu>
</div>
</template>

<script>
import moment from 'moment'
export default {
  name: 'ConsultarRangoFecha',
  components:{
    FechaInput: () => import(
      /* webpackChunkName: "fecha-input" */
      '@/widgets/FechaInput'
    ),
  },
  props:{
    fecha: {
      type: Object,
      default: () => ({
        inicio: '',
        final: '',
      })
    },
    allItems:{
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      fecha_inicio: '',
      fecha_fin: '',
      range_inicio: moment().startOf('week').day(1).format('YYYY-MM-DD'),
      range_fin: moment().format('YYYY-MM-DD'),
      selected_date: 'semana',
      selected_text: 'semana',
      error:{
        active: false,
        msg: ''
      },
      menu: false
    }
  },
  created(){
    this.fecha_inicio = moment().startOf('week').day(1).format("YYYY-MM-DD");
    this.fecha_fin = moment().format('YYYY-MM-DD');
  },
  methods:{
    consultarDatos() {
      this.error ={
        active: false,
        msg: ''
      };
      // this.fecha_fin = '';
      // this.fecha_inicio = '';
      switch (this.selected_date) {
        case 'semana':
          this.fecha_inicio = moment().startOf('week').day(1).format("YYYY-MM-DD");
          this.fecha_fin = moment().day(6).format("YYYY-MM-DD");
          break;
        case 'personalizado':
          const maxDate = moment(this.range_inicio).add(5, 'd');

          if(!moment(this.range_inicio).isSameOrBefore(this.range_fin)){
            this.error ={
              active: true,
              msg: 'La fecha de inicio debe ser menor que la fecha final'
            };
            return;
          }
          if(!moment(this.range_fin).isSameOrBefore(maxDate)){
            this.error ={
              active: true,
              msg: 'El rango de fecha debe ser menor o igual a 5 días.'
            };
            return;
          }

          this.fecha_inicio = this.range_inicio;
          this.fecha_fin = this.range_fin;

          break;
        default:
          break;
      }
      this.selected_text = this.selected_date;
      this.menu = false;
      this.$emit('consultar', {ini: this.fecha_inicio, fin: this.fecha_fin, selected: this.selected_date});
    }
  },
}
</script>
